import Banner from "../../components/Banner"
import { Text, Box } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import SolutionGrid from "../../components/SolutionsGrid"
import SolutionTextBox from "../../components/SolutionTextBox"

export const query = graphql`
  query {
    mobileBanner: file(
      relativePath: { eq: "solutionsOutdoorBannerMobile.png" }
    ) {
      ...fluidImage
    }
    desktopBanner: file(relativePath: { eq: "solutionsOutdoorBanner.png" }) {
      ...fluidImage
    }
    imageOne: file(relativePath: { eq: "solutionsHome1.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "solutionsHome2.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "solutionsHome3.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "solutionsHome4.png" }) {
      ...fluidImage
    }
    imageFive: file(relativePath: { eq: "solutionsHome5.png" }) {
      ...fluidImage
    }
    imageSix: file(relativePath: { eq: "solutionsHome6.png" }) {
      ...fluidImage
    }
    imageSeven: file(relativePath: { eq: "solutionsHome7.png" }) {
      ...fluidImage
    }
    imageEight: file(relativePath: { eq: "solutionsHome8.png" }) {
      ...fluidImage
    }
    imageNine: file(relativePath: { eq: "solutionsHome9.png" }) {
      ...fluidImage
    }
    imageTen: file(relativePath: { eq: "solutionsHome10.png" }) {
      ...fluidImage
    }
    imageEleven: file(relativePath: { eq: "solutionsHome11.png" }) {
      ...fluidImage
    }
    imageTwelve: file(relativePath: { eq: "solutionsHome12.png" }) {
      ...fluidImage
    }
    imageThirteen: file(relativePath: { eq: "solutionsHome13.png" }) {
      ...fluidImage
    }
    imageFourteen: file(relativePath: { eq: "solutionsHome14.png" }) {
      ...fluidImage
    }
  }
`

export default function Home({ data }) {
  const images = [
    data.imageOne.childImageSharp.fluid,
    data.imageTwo.childImageSharp.fluid,
    data.imageThree.childImageSharp.fluid,
    data.imageFour.childImageSharp.fluid,
    data.imageFive.childImageSharp.fluid,
    data.imageSix.childImageSharp.fluid,
    data.imageSeven.childImageSharp.fluid,
    data.imageEight.childImageSharp.fluid,
    data.imageNine.childImageSharp.fluid,
    data.imageTen.childImageSharp.fluid,
    data.imageEleven.childImageSharp.fluid,
    data.imageTwelve.childImageSharp.fluid,
    data.imageThirteen.childImageSharp.fluid,
    data.imageFourteen.childImageSharp.fluid,
  ]

  return (
    <Layout>
      <SEO title="Home & Home Office Solutions" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Find a solution that suits your HOME!"
        body={
          <Text variant="default" color="accent">
            Our range of indoor greenery include innovative terrariums to lush
            potted green wonders, table top planters and lifestyle products to
            accent pieces for your wall, green solutions for small balconies to
            large terraces.
          </Text>
        }
        alt="Home & Home Office Solutions"
      />
      <SolutionGrid>
        <Box
          sx={{
            gridArea: [
              "1 / 1 / 2 / -1",
              null,
              null,
              null,
              "1 / 1 / span 7 / span 1",
            ],
          }}
        >
          <SolutionTextBox>
            A décor trend that never goes out of style is adding greenery
            indoors. Remember the money plant in repurposed glass bottles . Or
            the brass plant holder found in every home. Yup our Moms had them
            way before it became Insta famous. Plants are an integral part of
            everyday living and we are happy to offer wide variety of ways to
            incorporate them in our homes in style. We consider every part of
            the house as a potential mini garden be it your bookshelf or your
            coffee table, that sunny window sill or your big terrace. Let’s not
            forget our home offices now that we spend most of our times there.
            Make it look fresh, inspiring and green with our Work From Home
            edition. Why not build up your own oasis with one plant at a time
            with our wide range of subscription options. We introduce new
            designs every few months as well as offer customised products and
            solutions for large spaces. Want greens but don’t know where to
            start-fret not, call us & we will do it for you.
          </SolutionTextBox>
        </Box>
        {images.map((image, index) => (
          <Img key={index} fluid={image} alt="home solutions" />
        ))}
      </SolutionGrid>
    </Layout>
  )
}
